.wrapper {
  @apply fixed w-screen h-screen top-0 left-0 bg-black bg-opacity-70 flex items-center justify-center z-100 pointer-events-none;

  .img {
    @apply w-150 h-150 bg-transparent;
  }

  .uploadWrapper {
    @apply flex items-center gap-1;

    .text {
      @apply text-20 phoneLandscape:text-24 desktop:text-30 font-bold text-white;
    }

    .uploadImg {
      @apply w-50 h-50 bg-transparent;
    }
  }
}
