.mainWrapper {
  @apply h-screen overflow-y-auto flex flex-col;

  .layoutWrapper {
    @apply flex flex-row items-center justify-between py-10 xl:px-20 tablet:px-10 phone:px-4 px-2  bg-white;
  
    .image {
      @apply desktop:h-60 phoneLandscape:h-60 h-40;
    }
  
    .menu {
      @apply flex flex-row gap-30 px-10 items-center w-full;
    }
  }
  
  .wrapper {
    @apply flex flex-col large:flex-row gap-2 w-full items-start h-full flex-1;
  
    .descriptionField {
      @apply w-full order-2 large:order-1 large:w-1/3 hidden phoneLandscape:flex large:h-full;
    }
    
    .canvasField {
      @apply w-full order-1 large:order-2 large:w-2/3 flex flex-col h-full;
    
      // .canvasWrapper {
      //   @apply h-500 large:h-full large:flex-1;
    
      //   .canvas {
      //     @apply w-full h-full text-gray-light flex justify-center items-center text-40;
      //   }
      // }
    
      // .controller {
      //   @apply flex flex-row items-center bg-blue-light;
    
      //   .iconWrapper {
      //     @apply flex items-center justify-center w-60;
    
      //     .icon {
      //       @apply w-60 h-40 bg-blue-light cursor-pointer;
      //     }
      //   }
    
      //   .overflow {
      //     @apply w-full overflow-x-auto;
    
      //     .tabButtonWrapper {
      //       @apply w-full min-w-820 flex overflow-x-auto justify-between desktop:px-10 px-2 bg-blue-light;
      //     }
      //   }
      // }
    
      // .objectsWrapper {
      //   @apply w-full bg-gray-light py-2;
    
      //   .title {
      //     @apply text-gray-dark pl-4 pt-2 font-bold select-none;
      //   }
      // }
    }
  }

  .newSession {
    @apply flex w-full;
  }
}  

