.wrapper {
  @apply relative  z-40 tablet:w-90 cursor-pointer;

  .selected {
    @apply flex flex-row justify-between items-center w-full gap-2 rounded-lg bg-gray-light tablet:py-2 tablet:pl-3 tablet:pr-10 p-0 shadow-md cursor-pointer;

    .text {
      @apply hidden tablet:block;
    }

    .flag {
      @apply h-20 tablet:h-30;
    }
  }

  .listWrapper {
    @apply absolute   w-full tablet:rounded-md bg-white p-0 tablet:py-1 text-16 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none;

    .item {
      @apply flex flex-row justify-between items-center cursor-default select-none tablet:py-2 tablet:pl-10 tablet:pr-4 p-0 text-gray-900;

      &.active {
        @apply bg-amber-100 text-amber-900;
      }

      .text {
        @apply font-normal hidden tablet:block;
      }

      .flag {
        @apply mt-1 h-20 tablet:h-25;
      }
    }
  }
}
