.saveModal {
  @apply max-w-500 mt-100 bg-white bg-opacity-100 #{!important};
}

.wrapper {
  @apply flex flex-col w-full overflow-y-hidden h-full;

  .titleWrapper {
    @apply w-full flex flex-row justify-center items-center mt-10 mb-20;
  
    .title {
      @apply text-20 tablet:text-30 phoneLandscape:text-25;
    }
  }
  
  .categoryWrapper {
    @apply my-60 mb-120 flex flex-col;
  
    .title {
      @apply flex flex-row items-center text-16 text-black font-bold gap-2;
  
      .lang {
        @apply text-12 text-black font-normal;
      }
    }
  
    .category {
      @apply border-gray-400;
    }
  }
}
