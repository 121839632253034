.transformWrapper {
  @apply absolute bottom-0 right-0 flex flex-col;

  .transform {
    @apply px-2 py-1 w-40 h-40 bg-gray-400 hover:bg-white z-40 cursor-pointer;

    &.selected {
      @apply bg-white;
    }
  }
}

.delete {
  @apply absolute  z-40 bottom-132 right-0 w-40 h-40 px-2 py-2 bg-gray-400 hover:bg-white cursor-pointer;
}

.clone {
  @apply absolute  z-40 bottom-184 right-0 w-40 h-40 px-2 py-2 bg-gray-400 hover:bg-white cursor-pointer;
}

.center {
  @apply absolute bottom-176 right-0 w-40 h-40 px-2 py-2 bg-gray-400 hover:bg-white cursor-pointer;
}
