// .wrapper {
//   @apply absolute bottom-30 left-1/2 transform -translate-x-1/2 max-w-full overflow-x-auto px-20 z-30 opacity-50 bg-black;

//   .group {
//     @apply flex  items-center gap-5 w-full;

//     .buttonWrapper {
//       @apply flex items-center justify-center border-2 border-white rounded-full min-w-40 h-40 ml-1 cursor-pointer;

//       .button {
//         @apply rounded-full w-28 h-28;
//       }
//     }
//   }
// }

.wrapper {
  @apply flex flex-col;

  .group {
    @apply flex flex-row w-full overflow-x-auto py-1 pr-40;

    .buttonWrapper {
      @apply flex items-center justify-center border-2 border-white rounded-full min-w-25 h-25 ml-1 cursor-pointer;

      .button {
        @apply rounded-full w-15 h-15;
      }
    }
  }
}
