.wrapper {
  @apply absolute -top-15 phoneLandscape:-top-20 right-40 flex flex-row items-center gap-1 phoneLandscape:gap-2;

  .button {
    @apply items-center justify-center gap-1 w-50 phoneLandscape:w-100 hidden phoneLandscape:flex py-1 text-white;

    &.en {
      @apply bg-sky-700;
    }

    &.fr {
      @apply bg-gray-400;
    }

    &.du {
      @apply bg-red-500;
    }

    &.ge {
      @apply bg-gray-700;
    }

    &.active {
      @apply border-2 border-black;
    }

    .icon {
      @apply h-15;
    }

    .name {
      @apply text-14 text-white;
    }
  }

  .buttonMobile {
    @apply items-center justify-center gap-1 w-55 phoneLandscape:w-100 flex phoneLandscape:hidden text-white;
    
    &.en {
      @apply bg-sky-700;
    }

    &.fr {
      @apply bg-gray-400;
    }

    &.du {
      @apply bg-red-500;
    }

    &.ge {
      @apply bg-gray-700;
    }

    &.active {
      @apply border-2 border-black;
    }

    .icon {
      @apply h-15;
    }

    .name {
      @apply text-14 text-white;
    }
  }
}