.wrapper {
  @apply w-full pt-20 relative bg-white;

  

  .close {
    @apply absolute right-15 top-5 h-25 w-25 cursor-pointer;

    &:hover {
      @apply h-30 w-30;
    }
  }

  .content {
    @apply w-full flex flex-col px-2 gap-3 overflow-y-auto border py-2 border-gray-400 rounded-5;
    
    &.isAddNewSubSession {
      @apply border-red-600 border-2;
    }
    
    .header {
      @apply w-full rounded-t-5 bg-slate-300 h-0 cursor-move;
    }

    .title {
      @apply text-20 tablet:text-24 text-blue-600 outline-blue-400 px-1;
  
      &::placeholder {
        @apply text-blue-300;
      }
    }

    .description {
      @apply w-full px-1 bg-gray-100 rounded-5 h-80 py-2;
    }
  
    .ideasWrapper {
      @apply w-full flex flex-row overflow-x-auto items-center py-2 px-2 gap-4;
  
      .plusWrapper {
        @apply w-150 min-w-150 h-full flex items-center justify-center;
  
        .icon {
          @apply w-35 h-35 cursor-pointer;
  
          &:hover {
            @apply w-45 h-45;
          }
        }
      }
    }
  }
}
