.title {
  @apply text-white mt-100 text-40 w-full text-center;
}

.contentWrapper {
  @apply w-full grid tablet:grid-cols-3 grid-cols-1 gap-40 mb-80;

  .event {
    @apply col-span-1 flex flex-col items-center justify-start gap-30;

    .title {
      @apply text-white text-30 w-full text-center;
    }

    .images {
      @apply flex items-center justify-between gap-10;

      .mouseImg {
        @apply w-40 h-40;
      }

      .or {
        @apply text-14 font-bold text-white;
      }

      .eventImg {
        @apply w-40 h-40;
      }
    }

    .description {
      @apply text-white text-16 w-full text-center;
    }
  }
}

.buttons {
  @apply w-full grid tablet:grid-cols-4 grid-cols-1 gap-40 mb-100;

  .button {
    @apply col-span-1 flex flex-col items-center justify-start gap-30;

    .img {
      @apply w-40 h-40;
    }

    .description {
      @apply text-white text-16 w-full text-center;
    }
  }
}

.helpModal {
  @apply tablet:max-w-5xl bg-opacity-50;
}
