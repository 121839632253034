.modal {
  @apply max-w-500 mt-50 bg-white bg-opacity-100 #{!important};
}


.wrapper {
  @apply flex flex-col mt-10 mb-10;
  
  .title {
    @apply text-16 text-black font-bold w-full text-center py-10;
  }

  .imageWrapper {
    @apply flex items-center justify-center w-full h-300 mt-5;
    
    .image {
      @apply object-contain h-300 w-full;
    }
  }

  .validText {
    @apply text-red-700 text-12;
  }
}