.wrapper {
  @apply w-full flex flex-col items-center h-full gap-20 pb-0 large:pb-10;
  
  .titleWrapper {
    @apply w-full max-w-820 flex flex-row gap-2 items-center;
    
    .title {
      @apply w-full text-center border rounded-md border-black text-18 p-1;
    }

    .addImageButtons {
      @apply flex gap-1 flex-row flex-nowrap;

      &.nonVisible {
        @apply hidden;
      }

      .addImageBtn {
        @apply relative flex items-center justify-center w-50 h-40 rounded-5 border border-gray-600 cursor-pointer;

        &.nonVisible {
          @apply hidden;
        }

        .image {
          @apply object-cover w-full max-h-30 cursor-pointer;
        }

        .imageWrapper {
          @apply w-full h-full overflow-hidden flex items-center justify-center relative;
          
          .image {
            @apply  w-full h-full object-cover;
          }
      
          .overlap {
            @apply w-full h-full absolute left-0 top-0 flex items-center justify-center;
      
            .icon {
              @apply w-30 h-30;
            }
          }
        }
        
        .upload {
          @apply absolute top-0 left-0 h-full w-full opacity-0 cursor-pointer;
        }
      }
    }

    .addButtons {
      @apply w-160 flex flex-col gap-1;

      &.nonVisible {
        @apply hidden;
      }

      .addBtn {
        @apply flex flex-nowrap gap-2 items-center text-16 text-gray-600 hover:underline relative;

        &.nonVisible {
          @apply hidden;
        }

        .icon {
          @apply w-15 h-15;
        }

        .upload {
          @apply absolute top-0 left-0 h-full w-full opacity-0;
        }
      }
    }
  }
  
  // .tox-tinymce {
  //   @apply h-500 large:h-full large:flex-1 border w-full overflow-y-auto px-2 #{!important};
  // }

  .button {
    @apply w-full max-w-200;
  }
}