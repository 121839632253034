.wrapper {
  @apply w-full flex items-center flex-nowrap gap-4 cursor-pointer select-none;

  .icon {
    @apply w-18 h-18;
  }

  .label {
    @apply text-18;

    &.selected {
      @apply font-bold;
    }
  }
}