.wrapper {
  @apply flex flex-row items-center gap-2;

  .icon {
    @apply w-40 h-35 bg-opacity-0 cursor-pointer;

    &.pencil {
      @apply block phoneLandscape:hidden;
    }
  }
}