.sessionsModal {
  @media screen and (max-width: 768px) {
    height: calc(100vh - 10px) !important;
  }
  height: calc(100vh - 40px) !important;
  @apply max-w-full mx-10 w-full bg-white bg-opacity-100 mb-0 overflow-hidden #{!important};
}


.wrapper {
  @apply w-full h-full flex flex-row gap-8 relative pt-20;

  .contentWrapper {
    @media screen and (max-width: 768px) {
      width: 100% !important;
      max-width: 100% !important;
    }

    width: calc(100vw - 360px) !important;
    max-width: calc(100vw - 10px) !important;

    @apply flex flex-1 flex-col;

    .buttonField {
      @apply w-full h-150 tablet:h-100 large:h-50 pl-4 pr-4 phone:pr-8 pb-4 flex items-center -mt-10 large:mt-0 justify-between flex-wrap gap-2;

      
      .newSessionBtn {
        @apply cursor-pointer flex flex-row flex-nowrap items-center px-2 tablet:px-4 py-2 font-medium border-none shadow-sm text-14 phone:text-16 tablet:text-20 rounded-full text-white bg-primary border-transparent hover:bg-opacity-80;
        
        .icon {
          @apply w-4 phone:w-20 tablet:w-30 h-4 phone:h-20 tablet:h-30;
        }
      }

      .searchBarWrapper {
        @apply flex flex-wrap tablet:flex-nowrap flex-1 order-last large:order-2 gap-1 justify-center;

        .searchBar {
          @apply flex-1 max-w-500 min-w-250 phoneLandscape:min-w-430 tablet:min-w-350 desktop:min-w-280 h-40 desktop:h-45 border flex items-center justify-between rounded-12 px-10 gap-4;
      
          &.nameSearch {
            @apply order-3 xl:order-2
          }
  
          &.idSearch {
            @apply order-last xl:order-3
          }
  
          .searchIcon {
            @apply w-30 font-bold;
          }

          .searchIDIcon {
            @apply w-25 font-bold;
          }
      
          .input {
            @apply w-full border-none outline-none;
          }
      
          .filterIcon {
            @apply w-30 cursor-pointer ;
          }
          
          .closeIconWrapper {
            @apply w-20 h-17 rounded-full border border-gray-400 flex items-center justify-center bg-white hover:bg-gray-200 cursor-pointer;
            
            .closeIcon {
              @apply w-10 cursor-pointer;
            }
          }
        }
      }

      .sortButton {
        @apply order-2 large:order-3
      }
    }

    .contents {
      @apply w-full h-full flex flex-col pb-10;

      .pagination {
        @apply w-full h-40 flex items-center pb-5;
      }

      .sessions {
        height: calc(100% - 90px);
        @apply w-full py-4 overflow-scroll;

        .table {
          @apply min-w-full w-full divide-y divide-gray-200;

          .thead {
            @apply bg-gray-50;

            .th {
              @apply px-6 py-3 text-left text-14 font-medium text-black uppercase;

              &.checkbox {
                @apply pl-2 pr-0 w-20;
              }
            }

            .thEvent {
              @apply relative px-6 py-3;
            }
          }

          .tbody {
            @apply bg-white divide-y divide-gray-200;

            .tr {
              @apply hover:bg-gray-200;

              .td {
                @apply px-6 py-4 whitespace-nowrap text-14 text-gray-500 cursor-pointer;

                &.bold {
                  @apply font-medium text-gray-900;
                }

                &.checkbox {
                  @apply pl-2 pr-0 w-20 z-50;
                }

                .name {
                  @apply flex flex-row items-center gap-2;

                  .icon {
                    @apply w-15 h-15;
                  }
                }
              }

              .tdEvent {
                @apply pl-6 pr-1 py-0 whitespace-nowrap text-right;

                .icon {
                  @apply w-18 h-18;
                }
              }
            }
          }
        }
      }
    }
  }
}
