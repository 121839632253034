.wrapper {
  @apply fixed right-20 top-20 z-100;

  .mainWrapper {
    @apply bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative;

    .title {
      @apply font-bold;
    }
    
    .content {
      @apply block phone:inline;
    }

    .closeIcon {
      @apply absolute top-0 bottom-0 right-0 px-4 py-3 w-15 h-15;
    }
  }
}