.wrapper {
  @apply flex flex-row;

  .dropdownWrapper {
    @apply flex justify-end;

    .dropdown {
      @apply relative flex items-center w-30 h-30;
  
      .listIcon {
        @apply cursor-pointer hover:w-30 hover:h-30 w-25 h-25;
  
        &.select {
          @apply w-30 h-30;
        }
      }
  
      .arrowDiv {
        @apply absolute top-33 left-5 z-42 transform rotate-45 bg-white border-gray-400 border w-15 h-15;

        &.up {
          @apply top-auto bottom-33;
        }

        &.hidden {
          @apply top-34 bg-white border-none;
        }

        &.upHidden {
          @apply top-auto bottom-34 bg-white border-none;
        }
      }

      .mainField {
        @apply absolute top-40 -right-5 flex flex-col w-fit z-40 py-2 rounded-5 bg-white shadow-lg border border-gray-300 min-w-130;
  
        &.up {
          @apply top-auto bottom-40;
        }

        .button {
          @apply py-1 text-16 font-medium text-black whitespace-nowrap text-left select-none flex flex-row flex-nowrap items-center gap-2 bg-white hover:bg-gray-200 px-4;

          &.delete {
            @apply text-rose-600;
          }
  
          .icon {
            @apply w-15 h-15;
          }
        }

        .line {
          @apply border-t border-gray-200;
        }
      }
  
      .background {
        @apply w-screen h-screen fixed top-0 left-0 bg-transparent z-40;
      }
    }
  }
}
