.saveModal {
  @apply max-w-500 mt-100 bg-white bg-opacity-100 #{!important};
}

.titleWrapper {
  @apply w-full flex flex-row justify-center items-center mt-10 mb-20;

  .title {
    @apply text-20 tablet:text-30 phoneLandscape:text-25;
  }
}

.selectWrapper {
  @apply w-full border border-gray-400 outline-none rounded-5 my-1;
}

.savedWrapper {
  @apply flex flex-col;

  .title {
    @apply text-16 text-black font-bold;
  }

  .savedField {
    @apply px-2 py-2 w-full bg-gray-200 h-150 overflow-y-auto flex flex-col gap-1;

    .button {
      @apply px-1 text-14 text-black text-left hover:bg-gray-300;
    }
  }
}

.categoryWrapper {
  @apply mt-10 flex flex-col;

  .title {
    @apply text-16 text-black font-bold;
  }

  .category {
    @apply border-gray-400;
  }
}

.inputWrapper {
  @apply flex flex-col mt-10 mb-10;

  .validText {
    @apply text-red-700 text-12;
  }
  .input {
    @apply outline-none px-2 border border-gray-400 rounded-5 py-2;
  }
}

.fileUpload {
  @apply flex gap-2 items-center mb-20;

  .fileLabel {
    @apply text-16 text-black font-bold;
  }
}
