.saveModal {
  @apply max-w-500 mt-100 bg-white bg-opacity-100 #{!important};
}

.titleWrapper {
  @apply w-full flex flex-row justify-center items-center mt-10 mb-20;

  .title {
    @apply text-20 tablet:text-30 phoneLandscape:text-25;
  }
}

.categoryWrapper {
  @apply my-50 flex flex-col gap-2;

  .title {
    @apply text-16 text-black font-bold;
  }

  .category {
    @apply border-gray-400;
  }
}

