// .wrapper {
//   @apply absolute bottom-30 left-1/2 transform -translate-x-1/2 max-w-full  bg-black bg-opacity-50 overflow-x-auto z-30 px-20;

//   .overflow {
//     @apply flex items-center gap-10 w-full px-20;

//     .button {
//       @apply flex flex-col justify-center items-center w-full;

//       .imageWrapper {
//         @apply w-50 h-50 rounded-sm p-5 flex items-center justify-center;
//       }
//     }
//   }
// }

.wrapper {
  @apply flex flex-col;

  .overflow {
    @apply flex flex-row w-full overflow-x-auto py-1 pr-40;

    .button {
      @apply flex items-center justify-center rounded-full w-45 h-45 ml-1 cursor-pointer;

      .imageWrapper {
        @apply rounded-full flex items-center justify-center w-full h-full object-cover;

        &.demo {
          @apply grayscale blur-xs;
        }
      }
    }
  }
}
