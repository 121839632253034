.wrapper {
  @apply w-full flex flex-col;

  .title {
    @apply w-full text-24 tablet:text-30 font-bold px-1 outline-gray-500 mt-4 mb-10;
  }

  .description {
    @apply w-full px-1 bg-gray-100 rounded-5 h-100 py-2;
  }

  .subSessionWrapper {
    @apply flex flex-col w-full;
  }

  .plusWrapper {
    @apply w-full h-150 flex justify-center items-center;

    .icon {
      @apply w-50 h-50 cursor-pointer;

      &:hover {
        @apply w-60 h-60;
      }
    }
  }
}