.wrapper {
  @apply flex items-center justify-center py-4 px-1 border-b-4 border-blue-light text-gray-300 select-none;

  &.selected {
    @apply border-white text-white font-medium;
  }

  .groupNumWrapper {
    @apply border border-white rounded-full flex items-center justify-center h-25 w-25 mr-2;

    &.selected {
      @apply bg-white text-gray-dark;
    }
  }

  .groupNameWrapper {
    @apply desktop:text-18 text-14;
  }
}
