.sessionIdeasModal {
  @media screen and (max-width: 768px) {
    height: calc(100vh - 10px) !important;
  }
  height: calc(100vh - 40px) !important;
  @apply max-w-1100 mx-10 w-full bg-white bg-opacity-100 mb-0 overflow-hidden #{!important};
}

.wrapper {
  @apply h-full w-full overflow-y-hidden;

  .actionWrapper {
    @apply flex justify-between items-center flex-nowrap;
    width: calc(100% - 50px);

    .approveWrapper {
      @apply flex items-center gap-1 flex-col phoneLandscape:flex-row phoneLandscape:gap-3;

      .approve {
        @apply text-16 font-bold mt-1 flex items-center flex-nowrap gap-1;
  
        .icon {
          @apply w-15 h-15;
        }
      }  
    }

    .modify {
      @apply text-20 font-bold mt-1 flex items-center flex-nowrap gap-1;

      .editIcon {
        @apply w-18 h-18;
      }
    }
  }

  .content {
    @apply h-full w-full max-h-full min-h-full overflow-y-auto mt-30 mb-10 flex flex-col px-2 pb-50;
  
    .title {
      @apply w-full text-center text-24 tablet:text-30 font-bold pb-4;
    }
  
    .description {
      @apply py-4 text-16 px-1 w-full bg-gray-100 rounded-5;
    }
    
    .subWrapper {
      @apply w-full flex-col py-4;
  
      .subTitle {
        @apply w-full text-20 tablet:text-24 font-bold text-blue-600 outline-blue-400 px-1;
      }

      .subDescription {
        @apply px-2 py-2;
      }
    
      .ideas {
        @apply flex flex-row w-full overflow-x-auto gap-2 py-2 bg-white rounded-12 px-2;
      }
    }
  }
}