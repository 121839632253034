.wrapper {
  @apply flex flex-row;

  .dropdownWrapper {
    @apply flex justify-end;

    .dropdown {
      @apply relative flex items-center;
  
      .sortButton {
        @apply flex flex-row gap-2 items-center rounded-full px-2 tablet:px-4 py-1 text-16 text-black shadow-lg border border-gray-300;

        .listIcon {
          @apply cursor-pointer w-4 h-4;
        }

        .text {
          @apply text-14 tablet:text-16;
        }
      }
  
      .arrowDiv {
        @apply absolute top-53 right-42 z-50 transform rotate-45 bg-white border-gray-400 border w-15 h-15;

        &.hidden {
          @apply top-54 bg-white border-none;
        }
      }

      .mainField {
        @apply absolute top-60 right-0 flex flex-col w-fit z-40 py-2 rounded-5 bg-white shadow-lg border border-gray-300;
  
        .button {
          @apply py-1 text-14 tablet:text-16 font-medium text-black whitespace-nowrap text-left select-none flex flex-row flex-nowrap items-center gap-2 bg-white hover:bg-gray-200 px-4;

          &.select {
            @apply text-primary;
          }
  
          .icon {
            @apply w-15 h-15;
          }
        }

        .line {
          @apply border-t border-gray-200;
        }
      }
  
      .background {
        @apply w-screen h-screen fixed top-0 left-0 bg-transparent z-40;
      }
    }
  }
}
