.subscribeModal {
  @apply max-w-500 mt-100 bg-white bg-opacity-100 #{!important};
}

.titleWrapper {
  @apply w-full flex flex-row justify-center items-center mt-20 mb-20;

  .title {
    @apply text-20 tablet:text-25 text-center;
  }
}

.descriptionWrapper {
  @apply w-full flex flex-row justify-center items-center mb-25;

  .description {
    @apply phoneLandscape:text-18 text-14;
  }

  .starIcon {
    @apply w-30 h-30 min-w-30 min-h-30;
  }
}
