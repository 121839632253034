.wrapper {
  @apply w-full h-300 border rounded-12 col-span-1 flex flex-col shadow-lg cursor-pointer hover:bg-gray-200 border-gray-100;

  &.approval {
    @apply border-green-600;
  }

  &.isShared {
    @apply border-blue-600;
  }

  &.dismiss {
    @apply border-red-600
  }

  .titleWrapper {
    @apply flex flex-row justify-between items-start px-4;

    .title {
      @apply text-black text-18 font-bold py-2;
    }

    .addButton {
      @apply z-40 cursor-pointer text-16 hover:font-bold text-white bg-primary px-2 py-1 rounded-2xl mt-1 flex flex-nowrap items-center;
    }
  }

  .imageWrapper {
    @apply w-full h-full px-4 overflow-hidden flex items-center justify-center relative;
    
    .image {
      @apply  w-full h-full object-contain;
    }

    .overlap {
      @apply w-full h-full absolute left-0 top-0 flex items-center justify-center;

      .icon {
        @apply w-40 h-40;
      }
    }
  }

  .category {
    @apply px-4 bg-amber-100 flex gap-2 items-center py-2 rounded-b-12;

    .icon {
      @apply w-18 h-18;
    }

    .label {
      @apply text-black text-18;
    }
  }
}