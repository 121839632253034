.wrapper {
  @apply relative w-full cursor-pointer;

  .selected {
    @apply  h-35 flex flex-row justify-between items-center w-full rounded-5  px-2 border border-black cursor-pointer;

    .icon {
      @apply w-15 h-15;
    }
  }

  .listWrapper {
    @apply absolute h-120 overflow-auto  w-full tablet:rounded-md bg-white p-0 tablet:py-1 text-16 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none;

    .item {
      @apply flex flex-row justify-between items-center cursor-default select-none tablet:py-2 tablet:pl-10 tablet:pr-4 p-0 text-gray-900;

      &.active {
        @apply bg-amber-100 text-amber-900;
      }

      .text {
        @apply font-normal;
      }
    }
  }
}
