.Wrapper {
  @apply flex flex-row justify-between items-center pr-2;

  .label {
    @apply text-14 my-5 font-medium select-none;
  }

  .slider {
    @apply w-90;
  }
}
