.wrapper {
  @apply flex flex-col justify-center items-center w-full;

  .imageWrapper {
    @apply w-90 h-90 rounded-sm p-5 flex items-center justify-center;

    &.demo {
      @apply grayscale blur-xs;
    }
  }

  .text {
    @apply text-black text-15 leading-1;

    &.demo {
      @apply text-gray-500;
    }

    &.selected {
      @apply font-bold;
    }
  }
}
