.menuWrapper {
  @apply min-w-300 w-300 h-full flex-col gap-20 overflow-y-auto hidden desktop:flex;

  .searchBar {
    @apply w-full h-50 border flex items-center justify-between rounded-12 px-20 gap-4;

    .searchIcon {
      @apply w-40 font-bold;
    }

    .input {
      @apply w-full border-none outline-none;
    }

    .filterIcon {
      @apply w-30 cursor-pointer ;
    }
  }
}

.miniMenuBar {
  @apply block desktop:hidden absolute top-5 -left-316 rounded-r-12 bg-gray-300 h-full z-10 max-h-full;

  &.show {
    @apply -left-4;
    
    @media screen and (max-width: 350px) {
      width: calc(100% - 10px);
    }
  }

  .relativeDiv {
    @apply relative h-full py-2;
    
    .miniMenuWrapper {
      @apply max-w-300 min-h-full max-h-full h-full w-full flex-col gap-20 overflow-y-auto flex p-4;
      
      

      .searchBar {
        @apply w-full h-50 border flex items-center justify-between rounded-12 px-20 gap-4;
    
        .searchIcon {
          @apply w-25 font-bold;
        }
    
        .input {
          @apply w-full border-none outline-none bg-gray-300;
        }
    
        .filterIcon {
          @apply w-30 cursor-pointer ;
        }
      }
    }

    .iconWrapper {
      @apply  absolute top-70 -right-30 flex items-center py-10 rounded-r-5 bg-gray-300;
      
      &.hiddenIcon {
        @apply -right-40 tablet:-right-50 w-35;
      }

      .textField {
        @apply text-black text-12;
        writing-mode: vertical-rl;
        text-orientation: upright; 
      }

      .showIcon {
        @apply h-30 w-30 cursor-pointer bg-gray-300;
      }
    }
  }
}


