.wrapper {
  @apply border w-full py-20 px-10 rounded-12;

  .titleWrapper {
    @apply flex flex-row items-center justify-between;

    .title {
      @apply flex flex-row gap-2 cursor-pointer items-center select-none;

      .icon {
        @apply w-15 h-15;
      }

      .text {
        @apply text-20 font-bold;
      }
    }

    .sort {
      @apply flex flex-row items-center cursor-pointer gap-2 select-none;

      .icon {
        @apply w-15 h-15 text-sky-900;
      }

      .label {
        @apply text-16;
      }
    }
  }

  .categories {
    @apply flex flex-col gap-2 w-full px-2 pt-4;

    .addCategory {
      @apply text-18 flex flex-row items-center gap-4 w-full border-none cursor-pointer;

      .icon {
        @apply w-18 h-18;
      }
    }
  }
}