.wrapper {
  @apply w-250 min-w-250 h-250 border rounded-12 flex flex-col shadow-lg cursor-pointer hover:bg-gray-300 border-gray-500;

  &.approval {
    @apply border-green-600;
  }

  &.isShared {
    @apply border-blue-600;
  }

  &.dismiss {
    @apply border-red-600;
  }

  .titleWrapper {
    @apply flex flex-row items-center pl-2 pr-4 gap-2 w-full;

    .numbers {
      @apply flex flex-row gap-1 items-center text-28 px-1 text-color-14 w-fit;

      .addNumber {
        @apply rounded-full w-30 h-30 flex items-center justify-center bg-blue-600 text-white text-20;
      }
    }

    .mainPart {
      @apply flex flex-row flex-1 justify-between items-center;

      .title {
        @apply text-black text-15 font-bold py-2 /*truncate w-200*/;
      }
  
      .action {
        @apply z-10 cursor-pointer hover:w-30 hover:h-30 w-25 h-25;
      }
    }
  }

  .imageWrapper {
    @apply w-full h-full p-2 overflow-hidden flex items-center justify-center relative;
    
    .image {
      @apply  w-full h-full object-contain;
    }

    .overlap {
      @apply w-full h-full absolute left-0 top-0 flex items-center justify-center;

      .icon {
        @apply w-40 h-40;
      }
    }
  }

  .category {
    @apply px-4 bg-amber-100 flex gap-2 items-center py-2 rounded-b-12;

    .icon {
      @apply w-18 h-18;
    }

    .label {
      @apply text-white text-18;
    }
  }
}