.canvasWrapper {
  height: calc(100vh - 343px);

  @media screen and (max-width: 1260px) {
    height: calc(100vh - 321px);
  }

  @apply w-full relative;

  &.increase {
    height: calc(100% - 0px);
    // @apply h-full
  }

  .extraTools {
    @apply absolute right-10 top-10 flex items-center gap-3;

    .lockIcon {
      @apply w-40 h-40 bg-opacity-0 cursor-pointer;
    }
  }

  .canvas {
    @apply  bg-gray-200 text-gray-light text-40;
  }

  .camera {
    @apply absolute right-20 top-10 w-50 h-45 bg-opacity-0 cursor-pointer;
  }

  .help {
    @apply absolute right-20 top-65 w-50 h-45 bg-opacity-0 cursor-pointer;
  }

  .pencil {
    @apply absolute right-20 top-120 w-50 h-45 bg-opacity-0 cursor-pointer;
  }

  .pdf {
    @apply absolute right-20 top-175 w-50 h-45 bg-opacity-0 cursor-pointer;
  }

  .bottomSetting {
    @apply absolute left-0 bottom-0 flex items-end w-full gap-5;

    .deleteFloor {
      @apply bg-gray-light rounded-sm  cursor-pointer select-none w-110 h-50 hover:bg-opacity-75 flex flex-row items-center justify-center;

      .img {
        @apply w-20 h-20;
      }
    }

    .color {
      @apply w-1/2  phoneLandscape:w-3/4;
    }
  }
}
