.folderModal {
  @media screen and (max-width: 768px) {
    height: calc(100vh - 10px) !important;
  }
  height: calc(100vh - 40px) !important;
  @apply max-w-full mx-10 w-full bg-white bg-opacity-100 mb-0 overflow-hidden #{!important};
}

.wrapper {
  @apply w-full h-full flex flex-row gap-8 relative pt-20;

  .contentWrapper {
    @apply w-full flex flex-col;

    .buttonField {
      @apply w-full h-100 tablet:h-50 pl-4 pr-4 phone:pr-8 pb-4 flex items-center -mt-10 tablet:mt-0 gap-2 justify-between flex-wrap;
      
      .newIdeaBtn {
        @apply order-1 cursor-pointer flex flex-row flex-nowrap items-center px-2 tablet:px-4 py-2 font-medium border-none shadow-sm text-14 phone:text-16 tablet:text-20 rounded-full text-white bg-primary border-transparent hover:bg-opacity-80;
        
        .icon {
          @apply w-4 phone:w-20 tablet:w-30 h-4 phone:h-20 tablet:h-30;
        }
      }

      .searchBar {
        @apply flex-1 max-w-500 min-w-300 h-40 tablet:h-45 border flex items-center justify-between rounded-12 px-10 gap-4 order-last tablet:order-2;
    
        .searchIcon {
          @apply w-30 font-bold;
        }
    
        .input {
          @apply w-full border-none outline-none;
        }
    
        .filterIcon {
          @apply w-30 cursor-pointer ;
        }

        .closeIconWrapper {
          @apply w-20 h-17 rounded-full border border-gray-400 flex items-center justify-center bg-white hover:bg-gray-200 cursor-pointer;
          
          .closeIcon {
            @apply w-10 cursor-pointer;
          }
        }
      }

      .sortButton {
        @apply order-3
      }
    }

    .contents {
      @apply w-full h-full flex flex-col pb-10 overflow-y-hidden;

      .pagination {
        @apply w-full h-40 flex items-center pb-5;
      }

      .ideas {
        @apply w-full px-4 py-4 flex-1 h-full overflow-y-auto grid grid-cols-1 tablet:grid-cols-3  desktop:grid-cols-3 xl:grid-cols-4 gap-4;
  
        .newIdea {
          @apply w-full h-300 border rounded-12 col-span-1 flex flex-col shadow-lg cursor-pointer hover:bg-gray-200;
        
          .title {
            @apply text-black text-18 px-4 font-bold py-2;
          }
        
          .imageWrapper {
            @apply w-full h-full px-4 overflow-hidden;
            
            .image {
              @apply  w-full h-full object-contain;
            }
          }
  
          .category {
            @apply px-4 bg-amber-100 flex gap-2 items-center py-2 rounded-b-12;
        
            .icon {
              @apply w-18 h-18;
            }
        
            .label {
              @apply text-black text-18;
            }
          }
        }
      }
    }
  }
}
