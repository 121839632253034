.wrapper {
  @apply cursor-pointer justify-center w-full px-4 py-1 text-16 font-medium border shadow-sm tablet:text-20 rounded-full;

  &.primary {
    @apply text-white bg-primary border-transparent hover:bg-opacity-80 border-4 border-green-200;
  }

  &.secondary {
    @apply text-white bg-secondary border-white  hover:bg-opacity-80 border-8;
  }

  &.third {
    @apply text-secondary hover:text-white border-4 border-primary hover:border-green-200 hover:bg-primary text-nowrap;
    
  }

  &.forth {
    @apply text-secondary hover:text-white border-4 border-secondary hover:border-white hover:bg-secondary text-nowrap;
  }
}
