.Wrapper {
  @apply flex flex-row justify-between items-center pr-1;

  .name {
    @apply truncate my-5 text-14 w-150 select-none;
  }

  .singleStepIcon {
    @apply mr-1;
  }
}
