.wrapper {
  @apply flex flex-row w-full;

  .menuWrapper {
    @apply hidden tablet:flex justify-between items-center gap-4 w-full;

    .menu {
      @apply flex items-center gap-1;

      .menuItem {
        @apply text-black text-20 hover:border-b border-black ml-4 font-bold uppercase flex items-center gap-1;

        .folderIcon {
          @apply w-18 h-18;
        }
      }

      .ideaItem {
        @apply bg-white text-black flex items-center px-2 py-2 justify-center gap-1 text-nowrap border border-gray-400 rounded-full;

        &.new {
          @apply hover:bg-primary hover:text-white hover:border-primary;
        }

        &.save {
          @apply hover:bg-secondary hover:text-white hover:border-secondary;
        }

        .icon {
          @apply w-18 h-18;
        }
      }
    }
  }

  .dropdownWrapper {
    @apply flex justify-end w-full  tablet:hidden;

    .dropdown {
      @apply relative flex items-center;
  
      .listIcon {
        @apply w-30 h-25 tablet:w-40 tablet:h-40 mr-0 tablet:mr-10 cursor-pointer rounded-t-sm;
  
        &.select {
          @apply bg-gray-300;
        }
      }
  
      .mainField {
        @apply absolute top-25 tablet:top-40 right-0 tablet:right-10 flex flex-col w-fit z-50 pt-2 bg-gray-300 rounded-sm px-2;
  
        .button {
          @apply px-1 py-1 rounded-lg text-16 tablet:text-20 font-medium text-gray-700  hover:text-gray-500 whitespace-nowrap text-left select-none flex flex-row flex-nowrap items-center gap-2;
  
          .icon {
            @apply w-18 h-18;
          }
        }
      }
  
      .background {
        @apply w-screen h-screen fixed top-0 left-0 bg-transparent z-50;
      }
    }
  }
}
