.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.slick-slider .slick-arrow.slick-next {
  right: 5px;
  z-index: 40;
}

.slick-slider .slick-arrow.slick-prev {
  left: 5px;
  z-index: 40;
}

.tox-tinymce {
  width: 100%;
  height: 100% !important;
}

.styles_video__b4G-g {
  width: auto;
  height: auto;
}

@media screen and (max-width: 1260px) {
  .tox-tinymce {
    height: 400px !important;
  }
}