.modal {
  @media screen and (max-width: 768px) {
    height: calc(100vh - 10px) !important;
  }
  height: calc(100vh - 40px) !important;
  @apply bg-white z-50 bg-opacity-100 max-w-900 #{!important};
}

.pdfViewButton {
  @apply cursor-pointer justify-center w-full px-4 py-1 text-16 font-medium shadow-sm text-center tablet:text-20 rounded-full text-white bg-primary border-transparent hover:bg-opacity-80 border-4 border-green-200;
}

.wrapper {
  @media screen and (max-width: 768px) {
    height: calc(100vh - 80px) !important;
  }
  @media screen and (max-width: 480px) {
    height: calc(100vh - 140px) !important;
  }
  height: calc(100vh - 120px) !important;
  @apply w-full h-full overflow-auto flex justify-center;
}

.contentWrapper {
  // width: 750px !important;
  // min-width: 750px !important;
  @apply h-full flex justify-center w-full;

  .content {
    @apply pt-10 pb-30 w-full flex-col;

    .logo {
      @apply h-30;
    }

    .title {
      @apply w-full text-center text-black text-20 phoneLandscape:text-24 tablet:text-30 font-bold my-30;
    }

    .imageWrapper {
      @apply w-full flex flex-col justify-center mb-30 gap-2;

      .buttons {
        @apply flex flex-row gap-2 justify-center;

        .buttonWrapper {
          @apply flex flex-col items-center;

          &.nonVisible {
            @apply hidden;
          }

          .button {
            @apply relative flex items-center justify-center w-50 h-50 rounded-5 border cursor-pointer;

            .image {
              @apply object-cover w-full h-full max-h-50 rounded-5;
            }

            .buttonImageWrapper {
              @apply w-full h-full overflow-hidden flex items-center justify-center relative;

              .image {
                @apply w-full h-full object-contain;
              }

              .overlap {
                @apply w-full h-full absolute left-0 top-0 flex items-center justify-center;

                .icon {
                  @apply w-30 h-30;
                }
              }
            }

            .over {
              @apply absolute top-0 left-0 h-full w-full opacity-0 cursor-pointer;
            }
          }

          .label {
            @apply text-12 font-bold;
          }
        }
      }

      .mainImageWrapper {
        @apply flex h-200 phoneLandscape:h-300 tablet:h-410 items-center justify-center;

        .mainImage {
          @apply h-full object-contain text-center flex items-center justify-center relative w-full;

          span:has(iframe) {
            width: 100% !important;
            height: 100% !important;
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
          }

          iframe {
            display: flex !important;
            width: 100% !important;
            height: 100% !important;
            min-width: 10px !important;
            min-height: 10px !important;
            object-fit: contain !important;
          }
        }
      }
    }

    .pdfModal {
      // @apply text-30 font-serif #{!important};

      ul {
        list-style-type: disc !important;
        list-style-position: inside !important;
        margin: 16px 0px;
        padding-left: 20px;
      }

      ol {
        list-style-type: decimal !important;
        list-style-position: inside !important;
        margin: 16px 0px;
        padding-left: 20px;
      }

      // p {
      //   @apply leading-38;
      // }

      .content main {
        height: 0px !important;
      }
    }
  }
}
