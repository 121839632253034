.wrapper {
  @apply absolute bottom-14 left-0 max-w-full bg-black bg-opacity-50  overflow-x-auto z-30;

  .overflow {
    @apply flex flex-row w-full overflow-x-auto py-1 pr-40;

    .button {
      @apply flex flex-col justify-center items-center w-full;

      .imageWrapper {
        @apply w-100 h-100 rounded-sm px-5 flex items-center justify-center;

        .img {
          @apply w-full;

          &.demo {
            @apply grayscale blur-xs;
          }
        }
      }
    }
  }
}
