.wrapper {
  @apply flex flex-row w-full;

  .dropdownWrapper {
    @apply flex w-full;

    .dropdown {
      @apply relative flex items-center w-full;
  
      .moveButton {
        @apply w-full py-1 text-16 font-medium text-black whitespace-nowrap text-left select-none flex flex-row flex-nowrap items-center gap-2 bg-white hover:bg-gray-200 pl-4;

        .listIcon {
          @apply cursor-pointer w-4 h-4;
        }
      }
  
      .arrowDiv {
        @apply absolute top-33 right-50 z-50 transform rotate-45 bg-white border-gray-400 border w-15 h-15;

        &.up {
          @apply -top-25;
        }

        &.hidden {
          @apply top-34 bg-white border-none;
        }

        &.upHidden {
          @apply -top-26 bg-white border-none;
        }
      }

      .mainField {
        @apply absolute overflow-y-auto top-40 right-40 flex flex-col w-fit z-45 py-2 rounded-5 bg-white shadow-lg border border-gray-300 h-110;
  
        &.up {
          @apply -top-126;
        }

        .button {
          @apply py-1 text-16 font-medium text-black whitespace-nowrap text-left select-none flex flex-row flex-nowrap items-center gap-2 bg-white hover:bg-gray-200 px-4;

          &.select {
            @apply text-primary;
          }
  
          .icon {
            @apply w-15 h-15;
          }
        }

        .line {
          @apply border-t border-gray-200;
        }
      }
  
      .background {
        @apply w-screen h-screen fixed top-0 left-0 bg-transparent z-40;
      }
    }
  }
}
