.wrapper {
  @apply fixed inset-0 z-50 overflow-y-auto;

  .content {
    @apply flex items-start justify-center h-full max-h-screen pt-6 pb-80 text-center tablet:p-0;

    .translation {
      @apply fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75;
    }

    .mainContentWrapper {
      @apply inline-block px-4 pt-5 pb-4 text-left align-bottom transition-all transform bg-black bg-opacity-40 rounded-lg shadow-xl tablet:my-8 tablet:align-middle tablet:max-w-5xl w-full tablet:p-6 relative;

      .closeIconWrapper {
        @apply absolute top-2 right-2 w-30 h-30 cursor-pointer z-10 flex items-center justify-center bg-black rounded-5;

        &.whiteColor {
          @apply bg-white;
        }

        .icon {
          @apply w-full h-full;
        }
      }

      .close {
        @apply absolute top-2 right-2 w-30 h-30 cursor-pointer z-10;

        &.black {
          fill: black;
        }
      }
      .mainContent {
        @apply mt-5 text-center tablet:mt-5;

        .title {
          @apply text-24 font-medium leading-24 text-gray-900 mb-4;
        }
      }

      .buttonWrapper {
        @apply flex flex-wrap gap-3 phoneLandscape:flex-nowrap mt-10;
      }
    }
  }
}
