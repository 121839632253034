.wrapper {
  @apply w-full bg-white pt-1;

  .title {
    @apply text-gray-dark pl-4 pt-2 font-bold select-none;
  }

  .line {
    @apply border-t border-gray-400 mx-1 my-1;
  }
}
