.wrapper {
  @apply absolute top-2 left-1 w-222 z-30;

  .header {
    @apply flex items-center rounded-t-12 bg-blue-light px-10 py-10 gap-10;

    .icon {
      @apply w-20 h-20;
    }

    .title {
      @apply text-white text-14 font-bold text-center   select-none;
    }
  }

  .contentWrapper {
    @apply min-h-50 bg-white flex flex-col px-1 py-2;

    .line {
      @apply border-t border-gray-300 my-1;
    }

    .animationWrapper {
      @apply ml-1;
    }
  }
}
