.wrapper {
  @apply w-full flex items-center justify-center desktop:justify-between tablet:px-4;

  .resultWrapper {
    @apply hidden desktop:block;
    
    .result {
      @apply text-14 text-gray-700;
  
      .text {
        @apply font-medium;
      }
    }
  }

  .content {
    @apply flex items-center;
  
    .pageLink {
        @apply py-1 px-3 rounded-lg;
  
        // .iconWrapper {
        //   @apply w-18 h-18 mx-3;
  
          .icon {
              @apply w-18 h-18;
          }
        // }
    }
  
    .selected {
        > a {
            @apply py-2 px-15 bg-neutral-900 text-white;
        }
    }
  }
}




