.pdfWrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #a0aec0;
}

.pdfContentWrapper {
  position: relative;
  background-color: #fff;
  max-width: 1024px;
  width: 100%;
  height: 100%;
}

.pdfDownload {
  position: absolute;
  top: 10px;
  right: 20px;
  font-weight: bold;
  color: #0787ea;
  z-index: 50;
}

.pdfMainWrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  height: 100%;
}

.pdfMainContentWrapper {
  overflow-y: auto;
  background-color: #fff;
  max-height: 100%;
  width: 100%;
}

.pdfMainContent {
  padding: 20px 30px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: relative;
}

.pdfLogoWrapper {
  width: 100%;
  justify-content: flex-start;
}

.pdfLogo {
  position: absolute;
  left: 20px;
  top: 10px;
  width: 144px;
  height: 48px;
  object-fit: contain;
}

.pdfTitle {
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: bold;
}

.pdfImageWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.pdfMainImage {
  height: 100%;
  max-height: 410px;
  object-fit: contain;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdfLoadingWrapper {
  width: 100%;
  height: 410px;
  background-color: #cbd5e0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdfLoadingIcon{
  width: 100px;
  height: 100px;
  background-color: transparent;
}

.pdfDescription {
  padding-bottom: 20px;
}

.pdfDescription tr {
  max-width: 100%;
}

.pdfDescription ul {
  /* list-style-type: disc !important;
  list-style-position: inside !important; */
  margin: 16px 0px;
  padding-left: 20px;
}

.pdfDescription li {
  /* list-style-type: disc !important;
  list-style-position: inside !important; */
  /* margin-top: 16px; */
  /* padding-left: 40px; */
}

.pdfDescription ol {
  /* list-style-type: decimal !important;
  list-style-position: inside !important; */
  margin: 16px 0px;
  padding-left: 20px;
}

/* .description p {
  line-height: 2.375rem;
} */

.main {
  height: 0px;
  padding: 0px;
  margin: 0px;
}

/* .clear {
  height: 40px;
  margin: 0px;
} */

/* .clear>:first-child {
  height: 0px;
  margin: 0px;
  font-size: ;
} */

@media screen and (max-width: 768px) {
  .title {
    font-size: 26px;
  }
}

@media screen and (max-width: 480px) {
  .title {
    font-size: 24px;
  }
}

@media screen and (max-width: 320px) {
  .title {
    font-size: 20px;
  }
}