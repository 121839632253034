.wrapper {
  @apply w-250 min-w-250 h-200 max-h-200 border border-dotted border-blue-600 flex rounded-5 relative bg-white;

  &.isAddNewIdea {
    @apply border-red-600 border-2;
  }

  &:hover {
    @apply bg-gray-200 cursor-pointer;
  }

  &.empty {
    @apply items-center justify-center;
  }

  &.noEmpty {
    @apply flex-col
  }

  .close {
    @apply absolute right-10 top-13 w-18 h-18;

    &:hover  {
      @apply w-20 h-20;
    }
  }

  .icon {
    @apply w-25 h-25 cursor-pointer;

    &:hover {
      @apply w-30 h-30;
    }
  }

  .titleWrapper {
    @apply flex flex-row items-center justify-between px-2 gap-2 h-50 select-none;

    .title {
      @apply text-black text-15 font-bold py-2 /*w-200 truncate*/;
    }

    .close {
      @apply w-18 h-18 cursor-pointer z-50;

      &:hover {
        @apply w-20 h-20;
      }
    }
  }

  .imageWrapper {
    @apply w-full flex-1 px-4 overflow-hidden flex items-center justify-center relative h-150 select-none;
    
    .image {
      @apply  w-full h-full max-h-132 object-contain select-none;
    }

    .overlap {
      @apply w-full h-full absolute left-0 top-0 flex items-center justify-center;

      .icon {
        @apply w-40 h-40;
      }
    }
  }
}