.carousel {
  @apply flex relative items-center h-full;

  .sliders {
    @apply w-full overflow-hidden flex h-full;

    .item {
      @apply transition-all duration-500 w-full min-w-full justify-center items-center flex h-full;

      &.order0 {
        @apply order-1;
      }
    
      &.order1 {
        @apply order-2;
      }
    
      &.order2 {
        @apply order-3;
      }

      .wrapper {
        @apply w-full flex flex-col h-full;
      
        // .wrapper:first-child {
        //   @apply w-full h-500 #{!important};
        // }
        
        .canvasPart {
          @apply large:h-full flex-col flex-1;
        }
      
        .controller {
          @apply flex flex-row items-center bg-blue-light;
      
          .iconWrapper {
            @apply flex items-center justify-center w-60;
      
            .icon {
              @apply w-60 h-40 bg-blue-light cursor-pointer;
            }
          }
      
          .overflow {
            @apply w-full overflow-x-auto h-63;
      
            .tabButtonWrapper {
              @apply w-full min-w-820 flex overflow-x-auto justify-between desktop:px-10 px-2 bg-blue-light;
            }
          }
        }
      
        .objectsWrapper {
          @apply w-full h-10 bg-white;

          &.show {
            @apply h-200;
          }
        }
      
        .image {
          @apply w-full h-full object-contain flex items-center justify-center #{!important};

          &.img {
            max-height: calc(100% - 100px) !important;
          }

          &.iframeTag {
            width: 100% !important;
            height: 100% !important;
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
          }
          
          span:has(iframe) {
            width: 100% !important;
            height: 100% !important;
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
          }

          iframe {
            display: flex !important;
            width: 100% !important;
            height: 100% !important;
            min-width: 10px !important;
            min-height: 10px !important;
            object-fit: contain !important;
          }
        }
      
        .textWrapper {
          @apply w-full h-full flex items-center justify-center;
      
          .text {
            @apply text-16;
          }
        }
      }
    }
  }

  .button {
    @apply absolute top-170 justify-center flex items-center text-center w-30 h-40 bg-gray-500 hover:bg-gray-400 cursor-pointer;

    &.left {
      @apply left-0;
    }

    &.right {
      @apply right-0;
    }

    .icon {
      @apply w-30 h-36;
    }
  }
}
